import RoutePath from "../models/route-path.model";
import Home from "../pages/home";
import TvCelulas from "../pages/tvCelula";
import TvFabrica from "../pages/tvFabrica";
import TvGrafico from "../pages/tvGrafico";
import Tv from "../pages/tv";

// ----------------------------------------------------------------------------
//         Constants
// ----------------------------------------------------------------------------
const protectedRoutes: RoutePath[] = [
  { path: '/', exact: true, component: Home },
  { path: '/tvcelula', exact: true, component: TvCelulas },
  { path: '/tvgrafico', exact: true, component: TvGrafico},
  { path: '/tvfabrica', exact: true, component: TvFabrica},
  { path: '/tv', exact: true, component: Tv}
];

export default protectedRoutes;
