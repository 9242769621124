
import { buttonStyle } from './style';
import MaterialIcon from '../template/MaterialIcon';
import AbstractButton from '../template/AbstractButton';
import Button from '../../../models/button.model';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const AccentButton = ({ iconName, title, onClick, type, style }: Button) => (
  <AbstractButton
    style={{ ...buttonStyle, ...style }}
    type={type}
    onClick={onClick}
  >
    {title && 
      <>{title}</>
    }
    {!title && 
      <MaterialIcon iconName={iconName} />
    }
  </AbstractButton>
);

export default AccentButton;