import Service from "./service";


/**
 * Responsible for managing cell requests. 
 */
class CellService extends Service {
  
  // --------------------------------------------------------------------------
  //         Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super();
  }


  // --------------------------------------------------------------------------
  //         Methods
  // --------------------------------------------------------------------------
  public async getWorkstation(cellId: number, workstationId: number): Promise<any> {
    const response = await this.remoteRequest.get(`cell/${cellId}/workstation/${workstationId}`);    
    
    return response.data;
  }

  public async   getFlatWorkstation(cellId: number, workstationId: number): Promise<any> {
    const response = await this.remoteRequest.get(`cell/${cellId}/workstation/${workstationId}/flat`);    
    
    return response.data;
  }

}

export default CellService;
