import styled from 'styled-components';

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    margin-top: 1rem;
`;

export const IconStyle = styled.span`
    color: #d1d1d1;
    margin-right: 16px;
`;

export const FieldTitleTVIcon = styled.div`
    display: flex;
    align-items: start;
    @font-face {
        font-family: 'Lato', Sans-serif;
        src: url('/fonts/Lato/Lato-Regular.ttf');
    }
    font-size: 32px;
    font-weight: 500;
    margin-left: 10rem;
`;

export const FieldValueTVIcon = styled.div`
    display: flex;
    align-items: center;
    @font-face {
        font-family: 'Lato', Sans-serif;
        src: url('/fonts/Lato/Lato-Regular.ttf');
    }
    font-size: 32px;
    font-weight: 500;
    margin-right: 2rem;
`;