import { useEffect, useState } from "react";
import * as Styled from "./styled";
import Clock from "../../components/time";
import DateDisplay from "../../components/date";
import RowFieldTV from "../../parts/RowFiedlTV";
import RowFieldTVBold from "../../parts/RowFieldTVBold/RowFieldTVBold";
import ProgressBarTV from "../../components/progressBarTV";
import CardTitleTV from "../../parts/CardTitleTV";
import LogoTVCelula from "../../components/LogoTVCelula";

// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const TvFabrica = () => {


  
  const [percentualAtualMeta, setPercentualAtualMeta] = useState(0);

  const [progress, setProgress] = useState(0);
  const metaFabrica = '1000';
  const prodTotalFabrica = '500';
  const ncTotalFabrica = '100';

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  
  return (
    <Styled.ContainerTV>
      <Styled.RowDataHora>
        <Styled.Left>
          <DateDisplay />
        </Styled.Left>
        <Styled.Right>
          <Clock />
        </Styled.Right>
      </Styled.RowDataHora>
      <Styled.Card>
        <Styled.Row>
            <CardTitleTV title={'Fábrica'} />
        </Styled.Row>
        <Styled.Row>
          <Styled.Left>
            <ProgressBarTV percentualAtualMeta={progress} corBarraLoading="#378E18" />
            <RowFieldTVBold label={'Meta da Fábrica'} value={metaFabrica + ' unidades'} />
            <RowFieldTV label={'Produção Total'} value={prodTotalFabrica + ' unidades'} />
            <RowFieldTV label={'Produção N/C'} value={ncTotalFabrica + ' unidades'} />
          </Styled.Left>
          <Styled.Right>
            <LogoTVCelula />
          </Styled.Right>
        </Styled.Row>
      </Styled.Card>
  </Styled.ContainerTV>
  );
};

export default TvFabrica;