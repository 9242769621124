import Field from "../../models/field.model";
import * as Styled from "./styled";

const RowFieldTVBold = ({ label, value }: Field) => {
  return (
    <Styled.Row>
        <Styled.FieldTitleTVBold>
            { label }
        </Styled.FieldTitleTVBold>
        <Styled.FieldValueTVBold>
            { value }
        </Styled.FieldValueTVBold>
    </Styled.Row>
  );
};

export default RowFieldTVBold;