import React from 'react';
import { PhotoDisplayWrapper } from './styled';

interface PhotoDisplayProps {
    image: string;
    status: string;
}

function PhotoDisplay({ image, status }: PhotoDisplayProps) {
  return (
    <PhotoDisplayWrapper >
      <img src={image} alt="Imagem de Perfil do Colaborador" />
      {/* Muda a cor do fundo conforme mudar o apontamento de parada */}
      <h4 style={
        {
          backgroundColor: status === 'ATIVO' ? "#DCF8D8" : "#E9E9E9",
          color: status === 'ATIVO' ? "#0F6A00" : "#535353 "
        }
      }>
        {status}
      </h4>
    </PhotoDisplayWrapper>
  );
}

export default PhotoDisplay;
