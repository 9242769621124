import StopReasonDto from "../dto/stop-reason.dto";
import Service from "./service";


/**
 * Responsible for managing stop reason requests. 
 */
class StopReasonService extends Service {
  
  // --------------------------------------------------------------------------
  //         Constructor
  // --------------------------------------------------------------------------
  constructor() {
    super();
  }


  // --------------------------------------------------------------------------
  //         Methods
  // --------------------------------------------------------------------------
  public async getOptions(): Promise<StopReasonDto[]> {
    const response = await this.remoteRequest.get('stopReason');    
    
    return response.data;
  }
}

export default StopReasonService;
