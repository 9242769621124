import Field from "../../models/field.model";
import * as Styled from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";

const RowFieldTVIcon = ({ label, value }: Field) => {
  return (
    <Styled.Row>
      <Styled.FieldTitleTVIcon>
        <div>
          <Styled.IconStyle>
            <FontAwesomeIcon icon={faClock} /> 
          </Styled.IconStyle>
          {label}
        </div>
      </Styled.FieldTitleTVIcon>
      <Styled.FieldValueTVIcon>
        {value}
      </Styled.FieldValueTVIcon>
    </Styled.Row>
  );
};

export default RowFieldTVIcon;
