import * as React from 'react';
import * as Styled from './styled';

interface ProgressBarProps {
  percentualAtualMeta: number;
  corBarraLoading: string;
}

function ProgressBar({ percentualAtualMeta, corBarraLoading }: ProgressBarProps) {

  return (
    <Styled.ProgressBar colorProgress={corBarraLoading}>
      <Styled.ProgressBarWrapper>
        <Styled.Progress percent={percentualAtualMeta} colorProgress={corBarraLoading}/>
      </Styled.ProgressBarWrapper>
      {/* Estilo definindo a cor do texto, xx%, ao lado da barra de
          progresso, que é diferente da cor da barra, nesta página apenas */}
      <span style={{ color: 'white', fontSize: '32px' }}>
        {percentualAtualMeta}%
      </span>
    </Styled.ProgressBar>
  );
}

export default ProgressBar;