import React from 'react';
import MaterialIcon from '../template/MaterialIcon';
import Button from '../../../models/button.model';
import AbstractButton from '../template/AbstractButton';
import { buttonStyle, titleStyle } from './style';

const OutlineButton = ({ title, onClick, type, style }: Button) => (
  <AbstractButton 
    style={{ ...buttonStyle, ...style }}
     type={type} 
     onClick={onClick}>

    {
      <img
        src={`/assets/images/svg/logoutIcon.svg`} 
        alt="Icon"
        style={{ marginRight: '8px' }} 
      />
    }
    {title && <span 
      style={titleStyle}>
        {title}
      </span>} 
  </AbstractButton>
);

export default OutlineButton;
