import styled, { createGlobalStyle } from "styled-components";
// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const ContainerTV = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  background-image: url("/assets/images/Background.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  min-height: 100vh;
  padding: 5px;
  @font-face {
    font-family: 'Jost', sans-serif;
  }
`;

export const Card = styled.div`
  display: flex;
  height: 694px;
  flex-direction: column;
  background: rgba(11, 11, 11, 0.9);
  border-radius: 10px;
  margin: 0px 5rem 0px 5rem;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 10px;
`;

export const RowDataHora = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 5rem;
  padding: 10px 0;
`;

export const Left = styled.div`
  flex: 1;
  color: #fff;
  margin-right: 10px;
  margin-top: 10px;
`;

export const Right = styled.div`
  flex: 1;
  color: #fff;
  margin-top: 10px;
`;

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Jost';
    src: local('Jost'), url(${'https://fonts.googleapis.com/css2?family=Jost:wght@600&display=swap'}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: local('Lato'), url(${'https://fonts.googleapis.com/css2?family=Lato&display=swap'}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;