import styled, { createGlobalStyle } from 'styled-components';

export const DateWrapper = styled.div`
    display: flex;
    justify-content: start;
    color: white;
    @font-face {
        font-family: 'Jost', sans-serif;
        url: (/fonts/Jost-VariableFont_wght.ttf)
    }
    font-size: 22px;
    font-weight: 500;
`;

// Define a GlobalStyle to apply the @font-face rule
export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Jost';
    src: local('Jost'), url(${'https://fonts.googleapis.com/css2?family=Jost:wght@600&display=swap'}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Lato';
    src: local('Lato'), url(${'https://fonts.googleapis.com/css2?family=Lato&display=swap'}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;
