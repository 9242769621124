import React, { useState, useEffect } from 'react';
import { ClockWrapper } from './styled'; // Importe o componente ClockWrapper

function Clock() {
  const [currentTime, setCurrentTime] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000); // Atualiza a cada minuto

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const formattedTime = currentTime.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

  return (
    <ClockWrapper>
      <p>{formattedTime}</p>
    </ClockWrapper>
  );
}

export default Clock;
