import styled from 'styled-components'

export const LogoContainerTV = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 12rem;
    margin-bottom: 2rem;
    opacity: 0.3;

    @media (max-width: 800px) {
        margin-top: 2rem;
    }

    img {
        width: 320px; 
        height: auto;
    }
`;