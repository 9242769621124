import styled from 'styled-components';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Box = styled.div`
  border: 1px solid rgba(72,46,25,0.5);
  border-radius: 8px;
  height: 70px;
  max-width: 130px;
  padding: 10px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: ;
  justify-content: space-between;

  @media(width < 400px) {
    max-width: 90px;
  }
`;

export const BoxLabel = styled.h3`
  color: #482E19;
  margin: 0;
  font-size: 20px;
  font-weight: 700;
  @font-face {
    font-family: 'Lato', 'sans-serif';
    src: url('https://fonts.googleapis.com/css2?family=Lato:wght@900&display=swap');
    }
`;

export const BoxValue = styled.p`
  margin: 0;
  color: #482E19;
  font-weight: 500;
  font-size: 20px;
  text-align: right;
`;
