import styled from 'styled-components';

export const PhotoDisplayWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    max-width: 135px;
    height: 175px;
    border-radius: 10px;
  }

  h4 {
    position: absolute;
    width: 135px;
    text-align: center;
    background-color: #DCF8D8;
    color: #0F6A00;
    font-size: 16px;
    font-weight: 700;
    padding:15px 0px 10px 0px;
    margin-top: 212px;
    border-radius: 10px;
    z-index: 1;
  }
`;