import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.div`
  flex: 1;
  background-color: #80764B;
  padding: 0 30px 30px 30px;
  min-height: 100vh;
  @media (max-width: 800px) {
    padding: 0 20px 0px 20px;
  }
`;

export const NameTitle = styled.h1`
  color: white;
  padding: 5px;
`;

export const NameTitleChecklist = styled.h1`
  color: #80764B;
  @font-face {
    font-family: 'Jost', sans-serif;
    src: url('/fonts/Jost-VariableFont_wght.ttf');
  font-size: 32px;
  font-weight: 500;
  padding: 5px;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 30px;
  border-radius: 16px;
  max-width: 800px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const RowDataHora = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px 0;
  max-width: 860px;
  margin: 0 auto;
`;

export const Right = styled.div`
  flex: 1;
`;

export const RightColab = styled.div`
  flex: 2;
  flex-direction: row;
`;

export const RigthLogin = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  padding-left: 3rem;
  padding-right: 1rem;
`;

export const Left = styled.div`
  flex: 1;
`;

export const LeftColab = styled.div`
  flex: 2;
`;

