import Field from "../../models/field.model";
import * as Styled from "./styled";

const RowFieldTV = ({ label, value }: Field) => {
  return (
    <Styled.Row>
      <Styled.FieldTitleTV>
        {label}
      </Styled.FieldTitleTV>
      <Styled.FieldValueTV>
        {value}
      </Styled.FieldValueTV>
    </Styled.Row>
  );
};

export default RowFieldTV;
