import FieldWithContent from "../../models/field-with-content.model";
import * as Styled from "./styled";


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const ColumnFieldAndContent = ({ label, children }: FieldWithContent) => (
  <Styled.Column>
    <Styled.Row>
      <Styled.FieldTitle>
        { label }
      </Styled.FieldTitle>
    </Styled.Row>
    { children }
  </Styled.Column>
)

export default ColumnFieldAndContent;
