import Field from "../../models/field.model";
import * as Styled from "./styled";

const RowFieldTVGrafico = ({ label, value }: Field) => {
    return (
        <Styled.Row>
            <Styled.FieldTitleTVGrafico>
                {label}
            </Styled.FieldTitleTVGrafico>
            <Styled.FieldValueTVGrafico>
                {value}
            </Styled.FieldValueTVGrafico>
        </Styled.Row>
        );
    };

export default RowFieldTVGrafico;