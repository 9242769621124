import * as Styled from "./styled";

const CardTitleTV = ({ title }: { title: string }) => (
  <Styled.ContainerHeaderTV>
    <Styled.ContainerNameTV>
      { title }
    </Styled.ContainerNameTV>
  </Styled.ContainerHeaderTV>
);

export default CardTitleTV;