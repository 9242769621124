import styled from 'styled-components'
import TextField from "@mui/material/TextField";
import { ReactComponent as LoginIcon } from '../../icons/login-icon.svg';

// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.div`
  background-color: #fff;
  border-radius: 16px;
  padding-left: 30px;
  padding-right: 30px;
`;

export const Input = styled(TextField)`
  width: 100%;
  margin-bottom: 10px;
`;

export const InputField = styled.div`
  margin: 20px 0;
`;

export const NameTitleLogin = styled.h1`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #80764B;
  @font-face {
    font-family: 'Jost', sans-serif;
    url('../fonts/Jost-VariableFont_wght.ttf');
  }
  font-size: 32px;
  font-weight: 500;
  padding-top: 20px;

`;

export const GlobalStyles = styled.div`
  @font-face {
    font-family: 'Jost';
    src: url('../fonts/Jost-VariableFont_wght.ttf');
    font-weight: 500;
  }
`;