import exp from 'constants';
import styled from 'styled-components';

export const ProgressBarWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 50%;
  height: 18px;
  margin-left: 2rem;
  background-color: white; 
  border-radius: 10px; 
  margin-right: 20px;
`;

export const Progress = styled.div<{ percent: number, colorProgress: string }>`
  width: ${({ percent }) => percent}%;
  height: 85%;
  align-items: center;
  background-color: ${(props) => props.colorProgress}; /* Cor de fundo azul */
  border-radius: 10px; /* Borda arredondada */
  transition: width 0.5s;
  margin: 2px;
`;

export const ProgressText = styled.span`
  width: 100%;
  display: flex;
  justify-content: end; 
  align-items: center;
`;

export const ProgressBar = styled.div<{ colorProgress: string }>`
  display: flex;
  align-items: center;
  color:  ${(props) => props.colorProgress}; Cor do texto que indica a porcentagem atual */ 
  @font-face {
    font-family: 'Lato', sans-serif;
    src: url('../../assets/fonts/Lato-Regular.ttf') format('truetype');
  }
`;
  