import Field from "../../models/field.model";
import * as Styled from "./styled";


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const ColumnField = ({ label, value }: Field) => (
  <Styled.Column>
    <Styled.Row>
      <Styled.FieldTitle>
        { label }
      </Styled.FieldTitle>
    </Styled.Row>
      <Styled.FieldValue>
        { value }
      </Styled.FieldValue>
  </Styled.Column>
)

export default ColumnField;
