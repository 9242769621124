import DataFactoryDto from "../dto/DataFactoryDto";
import Service from "./service";

class DataFactoryService extends Service {
    
    // --------------------------------------------------------------------------
    //         Constructor
    // --------------------------------------------------------------------------
    constructor() {
        super();
    }
    
    // --------------------------------------------------------------------------
    //         Methods
    // --------------------------------------------------------------------------
    // get all data factories from the api on /datafactories endpoint
    public async getDataFactories(): Promise<DataFactoryDto> {
        const response = await this.remoteRequest.get('datafactory');
        
        return response.data;
    }
}

export default DataFactoryService;