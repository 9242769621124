import styled, { createGlobalStyle } from 'styled-components';

export const ClockWrapper = styled.div`
    display: flex;
    justify-content: end;
    color: white;
    font-size: 22px;
    font-weight: 500;
    @font-face {
        font-family: 'Jost', sans-serif;
        url('assets/fonts/Jost-Regular.ttf')
    }
`;

// Define a GlobalStyle to apply the @font-face rule
export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Jost';
    src: local('Jost'), url(${'https://fonts.googleapis.com/css2?family=Jost:wght@600&display=swap'}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;