// Tv.js
import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Redirect, Route, NavLink, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import TvCelulaComponent from '../tvCelula';
import TvFabricaComponent from '../tvFabrica';
import TvGraficoComponent from '../tvGrafico';

const TvFabrica = () => {
  return <TvFabricaComponent />;
};

const TvCelula = () => {
  return <TvCelulaComponent />;
};

const TvGrafico = () => {
  return <TvGraficoComponent />;
};

const Tv = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const totalPagesCelula = 3; //devera ser setado via backend

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentPage(prevPage => (prevPage % totalPagesCelula) + 1);
    }, 5000); // Troca de página a cada 10 segundos

    // Limpa o intervalo ao desmontar o componente
    return () => clearInterval(interval);
  }, [totalPagesCelula]);
  

  return (
    <div>
      {currentPage === 1 && <TvFabrica />}
      {currentPage > 1 && currentPage <= totalPagesCelula && <TvCelula/>}
      {currentPage === totalPagesCelula + 1 && <TvGrafico />}
    </div>
  );
};

export default Tv;
