import { ChangeEvent, useEffect, useState } from "react";
import * as Styled from "./styled";
import Logo from "../../components/Logo";
import CellService from "../../services/cell.service";
import { parseError } from "../../config/error-handler";
import StopReasonService from "../../services/stop-reason.service";
import StopReasonDto from "../../dto/stop-reason.dto";
import SmallCard from "../../components/SmallCard";
import AuthService from "../../services/auth.service";
import Clock from "../../components/time";
import DateDisplay from "../../components/date";
import { useHistory } from "react-router-dom";
import SignInForm from "../../components/forms/signIn";
import Checkbox from "../../components/checklist";
import SmallCardMeta from "../../components/smallCardMeta";
import ChecklistService from '../../services/checklist.service';
import cellService from "../../services/cell.service";



// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const Login = () => {

  const cellService = new CellService();

  const history = useHistory();
  const [postoTrabalho, setPostoTrabalho] = useState(1);
  const [celula, setCelula] = useState(1);
  const [meta, setMeta] = useState(0);
  const [metaConfirmada, setMetaConfirmada] = useState(false);
  const [horarioConfirmado, setHorarioConfirmado] = useState(false);
  const [postoDeTrabalhoOrganizado, setPostoDeTrabalhoOrganizado] = useState(false);
  const [postoDeTrabalhoAbastecido, setpostoDeTrabalhoAbastecido] = useState(false);

  // get a setMeta and setCelula from the cellService
  useEffect(() => {
    cellService
      .getFlatWorkstation(celula, postoTrabalho)
      .then((response) => {
        setMeta(response.goal);
        setCelula(response.id);
      })
      .catch((error) => {
        console.error("Erro ao buscar os dados da célula:", parseError(error));
      });
  }, []);
  
  


  return (
    <Styled.Container>
      <Styled.RowDataHora>
        <Styled.Left>
          <DateDisplay />
        </Styled.Left>
        <Styled.Right>
          <Clock />
        </Styled.Right>
      </Styled.RowDataHora>
      <Logo />
      <Styled.Card>
        <Styled.Row>
          <Styled.Left>
            <SmallCard label="Posto de trabalho" value={postoTrabalho.toString()} />
            <SmallCard label="Célula" value={celula.toString()} />
            <SmallCardMeta label="Meta" value={meta.toString()} />
          </Styled.Left>
          <Styled.RigthLogin>
            <Styled.NameTitleChecklist>Checklist</Styled.NameTitleChecklist>
            <Checkbox
              label="Meta Confirmada"
              checked={metaConfirmada}
              onChange={(isChecked: boolean) => setMetaConfirmada(isChecked)}
            />
            <Checkbox
              label="Horário Confirmado"
              checked={horarioConfirmado}
              onChange={(isChecked: boolean) => setHorarioConfirmado(isChecked)}
            />
            <Checkbox
              label="Posto de Trabalho Organizado"
              checked={postoDeTrabalhoOrganizado}
              onChange={(isChecked: boolean) => setPostoDeTrabalhoOrganizado(isChecked)}
            />
            <Checkbox
              label="Posto de Trabalho Abastecido"
              checked={postoDeTrabalhoAbastecido}
              onChange={(isChecked: boolean) => setpostoDeTrabalhoAbastecido(isChecked)}
            />
          </Styled.RigthLogin>       
        </Styled.Row>
      </Styled.Card>
      <Styled.Card>
      <SignInForm onSuccess={() => handleSignIn(history, metaConfirmada, horarioConfirmado, postoDeTrabalhoOrganizado, postoDeTrabalhoAbastecido, celula, postoTrabalho)} />

      </Styled.Card>
    </Styled.Container>
  );
};

export default Login;

// ----------------------------------------------------------------------------
//         Functions
// ----------------------------------------------------------------------------
function handleSignIn(history: any, metaConfirmada: boolean, horarioConfirmado: boolean, postoDeTrabalhoOrganizado: boolean, postoDeTrabalhoAbastecido: boolean, celula: number, postoTrabalho: number) {
  setTimeout(async () => {
    try {
      const checklistService = new ChecklistService();
      
      const selectedChecklistItems = {
        metaConfirmada,
        horarioConfirmado,
        postoDeTrabalhoOrganizado,
        postoDeTrabalhoAbastecido,
      };

      // Enviar os itens do checklist para o serviço
      await checklistService.sendChecklistItems(selectedChecklistItems, celula, postoTrabalho);

      // Redirecionar para a página principal após o login
      history.push("/");
      window.location.reload();
    } catch (error) {
      console.error('Erro ao enviar os itens do checklist:', error);
    }
  }, 600);     
}
