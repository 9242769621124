import Field from "../../models/field.model";
import * as Styled from "./styled";


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const RowField = ({ label, value }: Field) => (
  <Styled.Row>
    <Styled.FieldTitle>
      { label }
    </Styled.FieldTitle>
    <Styled.FieldValue>
      { value }
    </Styled.FieldValue>
  </Styled.Row>
)

export default RowField;
