import exp from 'constants';
import styled from 'styled-components';


export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 15px;
  margin-left: 2rem;
  // usado para alinhar os itens no centro devido a diferença
  // de tamanho entre os campos
  // justify-content: space-between;
  // juntamente com margin-right: 14rem; em FieldValueTV
`;

export const FieldTitleTV = styled.div`
    display: flex;
    align-items: start;
    @font-face {
        font-family: 'Lato', Sans-serif;
        src: url('/fonts/Lato/Lato-Regular.ttf');
    }
    font-size: 32px;
    font-weight: 500;
`;

export const FieldValueTV = styled.div`
    display: flex;
    align-items: center;
    @font-face {
        font-family: 'Lato', Sans-serif;
        src: url('/fonts/Lato/Lato-Regular.ttf');
    }
    font-size: 32px;
    font-weight: 500;
    margin-right: 14rem;
`;

