import * as Styled from "./styled";

// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const CardTitle = ({ title }: { title: string }) => (
  <Styled.ContainerHeader>
    <Styled.ContainerName>
      { title }
    </Styled.ContainerName>
  </Styled.ContainerHeader>
);

export default CardTitle;
