import { TextField } from '@mui/material';
import styled from 'styled-components'


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Container = styled.div`
  flex: 1;
  background-color: #80764B;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 10px;
  min-height: 100vh;
`;

export const Card = styled.div`
  flex: 1;
  margin: auto;
  max-width: 800px;
  margin-top: 25px;
  background-color: white;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 30px;
  padding-bottom: 1px;
  border-radius: 16px;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
`;

export const RowDataHora = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 860px;
  margin: auto;
`;

export const ResponsiveRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;

  @media(width < 400px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const CenteredColumn = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
`;

export const Right = styled.div`
  flex: 1;
`;

export const RightFabrica = styled.div`
  flex: 3;
  flex-direction: row;
  @font-face {
    font-family: 'Lato';
    src: url('/fonts/Lato-Regular.ttf');
    font-weight: 400;
    font-size: 22px;
  }
  margin-top: 1rem;
  margin-left: 5rem;
`;

export const RightColab = styled.div`
  flex: 2;
  flex-direction: row;
`;

export const Left = styled.div`
  flex: 1;
`;

export const LeftFabrica = styled.div`
  flex: 1;
  flex-direction: row;
  margin-top: 1rem;
`;

export const InputDefault = styled(TextField)`
  width: 100%;
  font-family: lato, sans-serif;
  color: #0B0B0B;
  z-index: 0;
  background-color: rgb(244,241,228,0.5);
  
  .MuiOutlinedInput-notchedOutline {
    min-height: 40px;
    border-width: 1px;
    border: 1px solid rgba(72,46,25,0.5);
    border-radius: 8px;
  }
`;