import styled from 'styled-components'

// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
`;
