import React, { FunctionComponent } from 'react';
import { YesButton, NoButton, Message, ConfirmationButtons } from './styled';

interface ConfirmationModalProps {
	onConfirm: () => void;
	onCancel: () => void;
  message: string;
}

const ConfirmationModal: FunctionComponent<ConfirmationModalProps> = (props) => {
	const handleConfirm = () => {
	  props.onConfirm();
	};

	return (
		<React.Fragment>
      		<Message>{props.message}</Message>
      		<ConfirmationButtons>
        	<NoButton onClick={props.onCancel}>Cancelar</NoButton>
        	<YesButton onClick={props.onConfirm}>Confirmar</YesButton>
      		</ConfirmationButtons>
		</React.Fragment>
	);
};

export default ConfirmationModal;