import { useEffect, useState } from "react";
import * as Styled from "./styled";
import Clock from "../../components/time";
import DateDisplay from "../../components/date";
import CellService from "../../services/cell.service";
import { parseError } from "../../config/error-handler";
import RowFieldTV from "../../parts/RowFiedlTV";
import Chart from "react-google-charts";
import GraphTV from "../../components/graficoTV";
import LogoTV from "../../components/LogoTV";
import RowFieldTVGrafico from "../../parts/RowFieldTVGrafico";
import AccentButton from "../../components/buttons/AccentButton";

// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const TvGrafico = () => {

  const cellService = new CellService();
  const percentualAtualMeta = '50';
  const [funcionario, setFuncionario] = useState('');
  const [meta, setMeta] = useState(0);
  const [produzidos, setProduzidos] = useState(0);
  const [nc, setNc] = useState(0);
  const [postoTrabalho, setPostoTrabalho] = useState(1);
  const [celula, setCelula] = useState(1);
  
  
  useEffect(() => {
    setInterval(() => 
      cellService
      .getWorkstation(celula, postoTrabalho)
      .then(response => {
        setMeta(response.goal);
        setProduzidos(response.unitsProduced);
        setNc(response.unitsProducedNc);
      })
      .catch(parseError), 1000);
      }, []);


  return (
    <Styled.ContainerTV>
      <Styled.RowDataHora>
        <Styled.Left>
          <DateDisplay />
        </Styled.Left>
        <Styled.Right>
          <Clock />
        </Styled.Right>
      </Styled.RowDataHora>
      <Styled.Card>
        <Styled.Row style={{ justifyContent: 'center' }}>
          <RowFieldTVGrafico label={'Acompanhamento da Meta - Célula 0'} value={celula.toString()} />
        </Styled.Row>
        <Styled.RowGraph>
          <GraphTV />
        </Styled.RowGraph>
      </Styled.Card>
  </Styled.ContainerTV>
  );
};

export default TvGrafico;