import ChecklistItemDto from '../dto/checklist.dto';
import Service from './service';

class ChecklistService extends Service {

  constructor() {
    super();
  }

  public async sendChecklistItems(checklistItems: ChecklistItemDto, cell:number, workstation:number) {
    try {
      const response = await this.remoteRequest.post(`workstation/checklist/${cell}/${workstation}`, checklistItems);

      if (response.status === 201) {
        console.log('Checklist items sent successfully');
      } else {
        const errorMessage = response.statusText;
        console.error(`Failed to send checklist items. Status: ${response.status}, Error: ${errorMessage || 'No error message available'}`);
      }
    } catch (error: any) {
      console.error('An error occurred while sending checklist items:', error.message || 'No error message available');
    }
  }
}

export default ChecklistService;
