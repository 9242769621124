import styled from 'styled-components';

interface StyledCheckboxProps {
  checked: boolean;
}

export const CheckboxContainer = styled.label`
  display: block;
  font-size: 22px;
  font-weight: 400;
  font-family: 'Lato', sans-serif;
  margin-bottom: 15px;
  color: #393939;
`;

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`;

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  background-color: ${(props) => (props.checked ? '#403818' : 'transparent')};
  border: 3px solid ${(props) => (props.checked ? '#403818' : '#80764B')};
  border-radius: 4px;
  margin-right: 8px;

  &::before {
    content: ${(props) => (props.checked ? '"✔"' : '""')};
    color: white;
    font-size: 28px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    visibility: ${(props) => (props.checked ? 'visible' : 'hidden')};
  }
`;

export const CheckboxLabel = styled.span`
  margin-left: 8px;
  color: #393939;
`;