import styled from 'styled-components';

export const ChartContainer = styled.div`
  flex: 1;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  
  canvas {
    flex: 1;
    border-radius: 10px;
    border: 4px solid #212121;
    background-color: transparent;
    width: 100% !important;
    
  } 
`;

export const ContainerRow = styled.div`
  height: 650px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url('assets/images/svg/LogoViaAromaGrafico.svg');
  background-repeat: no-repeat;
  background-position: center;

  p {
    position: absolute;
    top: 12.75em;
    left: 5.5em;
    font-size: 1.5em;
    font-weight: 500;
    @font-face {
      font-family: 'Lato', sans-serif;
      url('assets/fonts/Lato-Regular.ttf');
    }
    color: #39DD00;
  }
`;