import styled from 'styled-components';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const FieldTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
`;

export const FieldValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: 1px solid rgba(72,46,25,0.5);
  border-radius: 8px;
  min-height: 40px;
`;
