export const buttonStyle: React.CSSProperties = {
  backgroundColor: 'transparent',
  color: 'white',
  border: '2px solid white',
  borderRadius: '8px',
};

export const fontFamily = {
  fontFamily: 'Lato',
  src: 'url("/fonts/Lato-Regular.ttf") format("truetype")',
};

export const titleStyle: React.CSSProperties = {
  ...fontFamily,
  fontSize: '16px',
  fontWeight: '700',
  textTransform: 'none',
};
