import styled from 'styled-components'
import IconUI from "@mui/material/Icon";


// ----------------------------------------------------------------------------
//         Constants
// ----------------------------------------------------------------------------
export const Icon = styled(IconUI)`
  text-align: center;
  font-size: 28px;
  color: ${(props) => props.color ?? 'white'};
`;
