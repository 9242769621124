import styled from 'styled-components'

export const Wrapper = styled.div`
    width 80%;
    @media(min-width: 768px) {
    width: 60%;
    }
    @media(min-width: 1024px) {
    width: 40%;
    }
`;

export const ConfirmationButtons = styled.div`
	display: flex;
	justify-content: center;
  padding: 1.5rem;
`;

export const Message = styled.div`
	font-size: 18px;
  font-weight: 600;
  @font-face {
    font-family: 'Lato', sans-serif;
    src: url('/fonts/Lato-Regular.ttf');
  }
	text-align: center;
  padding: 2rem;
`;

export const YesButton = styled.button`
	width: 9rem;
  height: 2rem;
  color: #80764B;
  background-color: #fff;
  border: 2px solid #80764B;
  border-radius: 8px;
  margin-left: 7rem;
  cursor: pointer;
  
  &:hover {
    background-color: #80764B;
    color: #fff;
    transition: 0.3s;
  }
`;

export const NoButton = styled.button`
	width: 9rem;
  height: 2rem;
  color: #80764B;
  background-color: #fff;
  border: 2px solid #80764B;
  border-radius: 8px;
  cusor: pointer;

  &:hover {
    background-color: #80764B;
    color: #fff;
    transition: 0.3s;
  }
`;