import styled from 'styled-components';

// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ContainerName = styled.h1`
  color: #80764B;
  margin: 0;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  margin-bottom: 20px;
`;
