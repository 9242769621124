import exp from "constants";
import styled from "styled-components";

export const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;
`;

export const FieldTitleTVGrafico = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @font-face {
        font-family: 'Jost', Sans-serif;
        src: url('/fonts/Jost/Jost-Regular.ttf');
    }
    font-size: 48px;
    font-weight: 500;
    color: #D1D1D1
`;

export const FieldValueTVGrafico = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    @font-face {
        font-family: 'Jost', Sans-serif;
        src: url('/fonts/Jost/Jost-Regular.ttf');
    }
    font-size: 48px;
    font-weight: 500;
    color: #D1D1D1
`;

