import React, { useState, useEffect } from 'react';
import { DateWrapper } from './styled'; 

function DateDisplay() {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date());
    }, 60000); // Atualiza a cada minuto

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // Ajuste do formato para DD/MM/AAAA
  const formattedDate = currentDate.toLocaleDateString('pt-BR', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  });

  return (
    <DateWrapper>
      <p>{formattedDate}</p>
    </DateWrapper>
  );
}

export default DateDisplay;
