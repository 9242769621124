import React, { useEffect, useRef } from 'react';
import Chart, { ChartData, ChartTypeRegistry } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import * as Styled from "./styled";

const BarChart = () => {
  const chartRef = useRef<HTMLCanvasElement | null>(null);
  const chartInstance = useRef<Chart | null>(null);

  useEffect(() => {
    if (!chartRef.current) return;

    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    const ctx = chartRef.current.getContext('2d');

    if (!ctx) return;

    const data: ChartData<keyof ChartTypeRegistry, number[], string> = {
      labels: ['8:00', '8:30', '9:00', '9:30', '10:00', '10:30', '11:00', '11:30', '12:00', '12:30', '13:00', '13:30', '14:00', '14:30', '15:00', '15:30', '16:00', '16:30', '17:00', '17:30', '18:00'],
      datasets: [
        {
          label: 'Linha Y=100',
          data: [100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100],
          borderColor: '#39DD00',
          type: 'line',
          fill: false,
          borderDash: [8, 8], // Padrão tracejado com 8px de traço e 8px de espaço
          borderWidth: 7,
          yAxisID: 'y3',
        },
        {
          label: 'Linha Azul - Meta',
          data: [10, 15, 12, 10, 8, 18, 20, 20, 30, 20, 20, 30, 40, 50, 30, 28, 38, 49, 50, 70, 60, 50],
          borderColor: '#277EFF',
          type: 'line',
          fill: false,
          borderWidth: 7,
          yAxisID: 'y',
        },
        {
          label: 'Barras - Produzidos',
          data: [10, 15, 12, 10, 8, 18, 20, 20, 30, 20, 20, 30, 40, 50, 30, 28, 38, 49, 50, 70, 60, 50],
          backgroundColor: '#80764B',
          type: 'bar',
          barPercentage: 0.5,
          barThickness: 40,
          yAxisID: 'y1',
        },
        {
          label: 'Linha Y=0',
          data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          borderColor: '#80764B',
          type: 'line',
          fill: false,
          borderWidth: 3,
          yAxisID: 'y2',
        },
      ],
    };

    chartInstance.current = new Chart(ctx, {
      type: 'bar',
      data: data,
      options: {
        scales: {
          x: {
            type: 'category',
            position: 'bottom',
            ticks: {
              color: '#ffffff',
              font: {
                family: 'Lato',
                size: 15,
                weight: 600,
              },
            },
          },
          // Grafico Barras
          y1: {
            display: false,
            beginAtZero: true,
            max: 120,
            min: 0,
            stacked: true,
            ticks: {
              color: '#ffffff',
              font: {
                family: 'Lato',
                size: 12,
                weight: 500,
              },
            },
          },

          // Grafico Linha Azul
          y: {
            display: false,
            beginAtZero: true,
            max: 120,
          },
          // Grafico Linha Y=0
          y2: {
            display: false,
            beginAtZero: true,
            max: 120,
          },
          // Grafico Linha Y=100
          y3: {
            display: false,
            beginAtZero: true,
            max: 120,
          },
        },
        plugins: {
          datalabels: {
            color: '#ffffff', 
            anchor: 'end',
            align: 'top',
            offset: -40,
            font: {
              family: 'Lato',
              size: 20,
              weight: 500,
            },
            formatter: (value, context) => {
              if (value === 0 || value === 100) {
                return '';
              }
              return value;
            },
          },
          legend: {
            display: false,
          },
        },
        elements: {
          bar: {
            borderRadius: 2,
          },
          line: {
            fill: false,
            tension: 0.1,
          },
          point: {
            radius: 0,
          },
        },
      },
      plugins: [ChartDataLabels],
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, []);

  return (
    <Styled.ChartContainer>
      <Styled.ContainerRow>
        <p>100%</p>
        <canvas ref={chartRef}> </canvas>
      </Styled.ContainerRow>
    </Styled.ChartContainer>
  );
};

export default BarChart;
