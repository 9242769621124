import Field from "../../models/field.model";
import * as Styled from "./styled";

// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const SmallCardMeta = ({ label, value }: Field) => (
  <Styled.Box>
    <Styled.BoxLabel>
      { label }
    </Styled.BoxLabel>
    <Styled.BoxValue>
      { value + " unidades" }
    </Styled.BoxValue>
  </Styled.Box>
);

export default SmallCardMeta;
