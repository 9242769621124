import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";
import * as Styled from "./styled";
import RadialChartProps from "../../../models/radial-chart-props.model";


// ----------------------------------------------------------------------------
//         Constants
// ----------------------------------------------------------------------------
const options: ApexOptions = {
  chart: {
    type: 'radialBar',
    offsetY: -20,
    sparkline: {
      enabled: true
    },
    foreColor: '#0F6A00',
  },
  colors: ['#0F6A00'],
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      track: {
        background: "#DCF8D8",
        strokeWidth: '97%',
        margin: 5, 
        dropShadow: {
          enabled: true,
          top: 2,
          left: 0,
          color: '#999',
          opacity: 1,
          blur: 2
        }
      },
      dataLabels: {
        name: {
          show: false
        },
        value: {
          offsetY: -2,
          fontSize: '42px',
           fontWeight: '550'
        }
      }
    }
  },
  grid: {
    padding: {
      top: -10
    }
  },
}


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const RadialChart = ({ series, legend }: RadialChartProps) => (
  <Styled.ChartContainer>
    <ReactApexChart options={options} series={series} type="radialBar" />
    <Styled.ChartLegend>
      { legend }
    </Styled.ChartLegend>
  </Styled.ChartContainer>
)

export default RadialChart;
