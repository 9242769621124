import { useEffect, useState } from "react";
import * as Styled from "./styled";
import Logo from "../../components/Logo";
import LineSeparator from "../../parts/LineSeparator";
import CellService from "../../services/cell.service";
import { parseError } from "../../config/error-handler";
import StopReasonService from "../../services/stop-reason.service";
import StopReasonDto from "../../dto/stop-reason.dto";
import CardTitle from "../../parts/CardTitle";
import RowField from "../../parts/RowField";
import SmallCard from "../../components/SmallCard";
import ColumnField from "../../parts/ColumnField";
import ColumnFieldAndContent from "../../parts/ColumnFieldAndContent";
import RadialChart from "../../components/charts/RadialChart";
import AuthService from "../../services/auth.service";
import Clock from "../../components/time";
import DateDisplay from "../../components/date";
import PhotoDisplay from "../../components/fotoColab";
import Modal from "../../components/modalParada";
import ConfirmationModal from "../../components/modalParadaConfirm";
import OutlineButton from "../../components/buttons/OutlineButton";
import AccentButtonWork from "../../components/buttons/AccentButtonWork";
import DataFactoryService from "../../services/datafactory.service";
import DataFactoryDto from "../../dto/DataFactoryDto";

// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const Home = () => {
  const cellService = new CellService();
  const stopReason = new StopReasonService();
  const authService = new AuthService();
  const dataFactory = new DataFactoryService();

  // get image from path src/assets/images/image.png and set it to image variable
  const image = '/assets/images/image.png';

  // Status: se não tem condição de parada, o status é ativo'
  const [status, setStatus] = useState('ATIVO');
  const [funcionario, setFuncionario] = useState('');
  const [meta, setMeta] = useState(0);
  const [produzidos, setProduzidos] = useState(0);
  const [nc, setNc] = useState(0);
  const [metaFabrica, setMetaFabrica] = useState(0);
  const [produzidosFabrica, setProduzidosFabrica] = useState(0);
  const [ncFabrica, setNcFabrica] = useState(0);
  const [postoTrabalho, setPostoTrabalho] = useState(1);
  const [celula, setCelula] = useState(1);
  const [parada, setParada] = useState<number>(0);
  const [tempParada, setTempParada] = useState<number>(0);
  const [opcoesParada, setOpcoesParada] = useState<StopReasonDto[]>([]);

  const onConfirm = () => toggle();
  const onCancel = () => {
    setParada(tempParada);  // Restaura o valor original
    toggle();
  };

  const useModal = (onConfirmCallback: () => void, onCancelCallback: () => void) => {
    const [isShown, setIsShown] = useState(false);

    const confirm = () => {
      setIsShown(false);
      if (onConfirmCallback) {
        onConfirmCallback();
      }
    };

    const cancel = () => {
      setIsShown(false);
      if (onCancelCallback) {
        onCancelCallback();
      }
    };

    const toggle = () => {
      setIsShown(!isShown);
    };

    return {
      isShown,
      toggle,
      confirm,
      cancel,
    };
  };

  const { isShown, toggle, confirm, cancel } = useModal(() => {
    if (parada === 0) {
      setStatus('ATIVO');
    } else {
      setStatus('INATIVO');
    }
  }, onCancel);

  useEffect(() => {
    setInterval(() => {
      cellService
        .getWorkstation(celula, postoTrabalho)
        .then(response => {
          setMeta(response.goal);
          setProduzidos(response.unitsProduced);
          setNc(response.unitsProducedNc);
        })
        .catch(parseError);
    }, 1000);

    stopReason
      .getOptions()
      .then(setOpcoesParada)
      .catch(parseError);

    authService
      .getAuthenticatedUser()
      .then(user => setFuncionario(`${user.name}`))
      .catch(parseError);

    dataFactory
      .getDataFactories()
      .then(response => {
        setMetaFabrica(response.totalGoal);
        setProduzidosFabrica(response.totalUnitsProduced);
        setNcFabrica(response.totalUnitsProducedNc);
      })
      .catch(parseError);
  }, []);

  return (
    <Styled.Container style={{ overflow: 'hidden' }}>
      <Styled.RowDataHora>
        <Styled.Left>
          <DateDisplay />
        </Styled.Left>
        <Styled.Right>
          <Clock />
        </Styled.Right>
      </Styled.RowDataHora>
      <Logo />
      <Styled.RowDataHora>
        <OutlineButton
          title='Sair'
          onClick={() => handleLogout()}
        />
        <AccentButtonWork
          style={{ display: status === 'ATIVO' ? `none` : `flex` }}
          title='Retomar trabalho'
          onClick={() => onBackWork(setParada, toggle, setTempParada, parada)}
        />
      </Styled.RowDataHora>
      <Styled.Card>
        <Styled.Row>
          <Styled.Left>
            <PhotoDisplay image={image} status={status} />
          </Styled.Left>
          <Styled.CenteredColumn>
            <SmallCard label="Posto de trabalho" value={postoTrabalho.toString()} />
            <SmallCard label="Célula" value={celula.toString()} />
          </Styled.CenteredColumn>
          <Styled.RightColab>
            <ColumnField label='Funcionário(a)' value={funcionario} />
            <ApontamentoParada parada={parada} setParada={(indice: number) => onChangeParada(indice, setParada, toggle, setTempParada, parada)} opcoesParada={opcoesParada} />
            <Modal
              isShown={isShown}
              hide={toggle}
              headerText=''
              modalContent={
                <ConfirmationModal
                  onConfirm={confirm}
                  onCancel={cancel}
                  message={parada === 0
                    ? 'Você confirma a volta ao trabalho?'
                    : 'Você confirma a parada pelo motivo "' + (opcoesParada[parada - 1]?.description ?? '') + '"?'
                  }
                />
              }
            />
          </Styled.RightColab>
        </Styled.Row>
      </Styled.Card>

      {/* style usado para aplicar transparencia do <Styled.Card>, controlado pela variavel status */}
      <Styled.Card style={{ backgroundColor: status === 'INATIVO' ? `rgba(255, 255, 255, 0.3)` : status === 'ATIVO' ? '#fff' : 'transparent' }}>
        <CardTitle title="Desempenho Pessoal" />
        <Styled.ResponsiveRow>
          <Styled.Left>
            <RowField label='Meta Posto' value={`${meta} unidades`} />
            <RowField label='Produzidas' value={`${produzidos} unidades`} />
            <RowField label='N/C' value={`${nc} unidades`} />
          </Styled.Left>
          <Styled.Right>
            <RadialChart series={[meta === 0 ? 100 : parseFloat((produzidos / meta * 100).toFixed(1))]} legend='Evolução na Meta' />
          </Styled.Right>
        </Styled.ResponsiveRow>
        <LineSeparator />
        <Styled.ResponsiveRow>
          <Styled.LeftFabrica>
            <CardTitle title="Desempenho da Fábrica" />
          </Styled.LeftFabrica>
          <Styled.RightFabrica>
            <RowField label='Meta Geral' value={`${metaFabrica} unidades`} />
            <RowField label='Produção Geral' value={`${produzidosFabrica} unidades`} />
            <RowField label='N/C Geral' value={`${ncFabrica} unidades`} />
          </Styled.RightFabrica>
        </Styled.ResponsiveRow>
      </Styled.Card>
    </Styled.Container>
  );
};

export default Home;

const ApontamentoParada = ({ parada, setParada, opcoesParada }: any) => (
  <ColumnFieldAndContent label='Apontamento de parada'>
    <Styled.InputDefault
      size="small"
      select
      value={parada}
      onChange={event => setParada(event.target.value)}
      SelectProps={{
        native: true,
      }}
    >
      <option value="0">Selecione</option>
      {opcoesParada?.map((option: any, index: number) => (
        <option key={option.id ?? index} value={option.id}>
          {option.description}
        </option>
      ))}
    </Styled.InputDefault>
  </ColumnFieldAndContent>
);

// ----------------------------------------------------------------------------
//         Functions
// ----------------------------------------------------------------------------

function onChangeParada(indiceParada: number, setParada: any, setIsShown: any, setTempParada: any, parada: number) {
  setTempParada(parada);  // Salva o valor atual
  setParada(indiceParada);
  setIsShown(true);
}

function onBackWork(setParada: any, setIsShown: any, setTempParada: any, parada: number) {
  setTempParada(parada);  // Salva o valor atual
  setParada(0);
  setIsShown(true);
}

function handleLogout() {
  const authService = new AuthService();
  authService.makeLogout();
}
