import { Icon } from './styled';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const MaterialIcon = ({ iconName, color }: any) => (
  <Icon color={color}>
    { iconName }
  </Icon>
);

export default MaterialIcon;
