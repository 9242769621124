import { useEffect, useState } from "react";
import * as Styled from "./styled";
import Clock from "../../components/time";
import DateDisplay from "../../components/date";
import CellService from "../../services/cell.service";
import { parseError } from "../../config/error-handler";
import RowFieldTV from "../../parts/RowFiedlTV";
import RowFieldTVBold from "../../parts/RowFieldTVBold/RowFieldTVBold";
import RowFieldTVIcon from "../../parts/RowFieldTVIcon";
import ProgressBarTV from "../../components/progressBarTV";
import LogoTVCelula from "../../components/LogoTVCelula";
import CardTitleTV from "../../parts/CardTitleTV";

// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
const TvCelula = () => {

  const cellService = new CellService();
  const [percentualAtualMeta, setPercentualAtualMeta] = useState(0);
  const [progress, setProgress] = useState(0);
  const [funcionario, setFuncionario] = useState('');
  const [meta, setMeta] = useState(0);
  const [produzidos, setProduzidos] = useState(0);
  const [nc, setNc] = useState(0);
  const [postoTrabalho, setPostoTrabalho] = useState(1);
  const [celula, setCelula] = useState(1);
  const horasTrabalhadas = '5h30';
  const finalTurno = '17h30';

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setInterval(() => 
      cellService
      .getWorkstation(celula, postoTrabalho)
      .then(response => {
        setMeta(response.goal);
        setProduzidos(response.unitsProduced);
        setNc(response.unitsProducedNc);
      })
      .catch(parseError), 1000);
      }, []);


  return (
    <Styled.ContainerTV>
      <Styled.RowDataHora>
        <Styled.Left>
          <DateDisplay />
        </Styled.Left>
        <Styled.Right>
          <Clock />
        </Styled.Right>
      </Styled.RowDataHora>
      <Styled.Card>
        <Styled.Row>
          <CardTitleTV title={'Célula '+celula.toString()} />
        </Styled.Row>
        <Styled.Row>
          <Styled.Left>
            <ProgressBarTV percentualAtualMeta={progress} corBarraLoading="#007bff"/>
            <RowFieldTVBold label={'Meta da Célula'} value={meta*3 + ' unidades'} />
            <RowFieldTV label={'Produção Total'} value={meta + ' unidades'} />
            <RowFieldTV label={'Produção N/C'} value={nc + ' unidades'} />
          </Styled.Left>
          <Styled.Right>
            <RowFieldTVIcon label={'Horas Trabalhadas'} value={horasTrabalhadas} />
            <RowFieldTVIcon label={'Final do Turno'} value={finalTurno} />
            <LogoTVCelula />
          </Styled.Right>
        </Styled.Row>
      </Styled.Card>
  </Styled.ContainerTV>
  );
};

export default TvCelula;