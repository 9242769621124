export const buttonStyle: React.CSSProperties = {
    backgroundColor: '#251F06',
    color: 'white',
    border: '2px solid #251F06',
    borderRadius: '8px',
  };
  
  export const fontFamily = {
    fontFamily: 'Lato',
    src: 'url("/fonts/Lato-Regular.ttf") format("truetype")',
  };
  
  export const titleStyle: React.CSSProperties = {
    ...fontFamily,
    fontSize: '16px',
    fontWeight: '700',
    textTransform: 'none',
  };
  