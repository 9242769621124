import styled from 'styled-components';


// ----------------------------------------------------------------------------
//         Components
// ----------------------------------------------------------------------------
export const ChartContainer = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ChartLegend = styled.p`
  font-size: 16px;
  text-align: center;
`;
